import styled from 'styled-components';

const BoldFont = 'Rubik-Bold';

const AmigoSection = styled.div`
  float: left;
  width: 33.33%;
  padding: 0 10px;
  margin: 17px 0;
  display: inline-block;
  height: 100px;

  @media (max-width: 1150px) and (min-width: 768px) {
    width: calc(100%/2);
  }

  @media (max-width: 600px) and (min-width: 401px) {
    width: calc(100%/2);
  }

  @media only screen and (min-width: 440px) and (max-width: 736px) and (orientation: landscape) {
    width: calc(100%/2);
  }

  @media (max-width: 400px) {
    width: 100%;
    height: auto;
    margin: 10px;
  }
`;

const AmigoInformation = styled.div`
  width: calc(100% - 70px);
  margin-left: 10px;
  float: left;
  text-align: left;
`;

const AmigoName = styled.p`
  font-size: 16px;
  color: #159fc9;
  margin-bottom: 0;
  text-transform: capitalize;
  font-family: ${BoldFont};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media (max-width: 400px) {
    font-size: 14px;
  }
`;

const StyledLink = styled.a`
  font-size: 12px;
  line-height: 20px;
  color: #ef6827;
  text-decoration: none;
  cursor: pointer;
  font-family: ${BoldFont};

  &:hover, &:active, &:focus {
    color: #ef6827;
    text-decoration: none;
  }
`;

const StyledHeading = styled.h1`
  font-size: 18px;
  letter-spacing: 0px;
  color: rgb(51,51,51);
  text-transform: capitalize;
  margin-bottom: 14px;
  padding: 0 12px;
`;

const AmigosContainer = styled.div`
  width: 100%;
  float: left;
  padding: 10px 20px 30px;
  border-top: 1px solid #dcd7d7;

  @media (max-width: 767px) and (min-width: 501px) {
    padding: 10px 10px 0;
  }

  @media (max-width: 500px) {
    padding: 10px 0 0;
  }
`;

const AmigosContainerV2 = styled.div`
  width: 100%;
  float: left;
  // padding: 10px 20px 30px;
  // border-top: 1px solid #dcd7d7;

  @media (max-width: 767px) and (min-width: 501px) {
    padding: 10px 10px 0;
  }

  @media (max-width: 500px) {
    padding: 10px 0 0;
  }
`;

const MutualFriends = styled.p`
  font-size: 12px;
  margin-bottom: 0;
  color: rgb(153,153,153);
`;

const ImageContainer = styled.div`
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
  float: left;
  overflow: hidden;

  @media (max-width: 400px) {
    width: 50px;
    height: 50px;
  }

  > img {
    width: 100%;
    height: 100%;
  }
`;

export { AmigoSection, AmigoInformation, AmigoName, StyledLink, StyledHeading, AmigosContainer, MutualFriends, ImageContainer, AmigosContainerV2 };
