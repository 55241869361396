import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import _ from 'lodash';

import { AmigoSection, AmigoInformation, AmigoName, StyledLink, StyledHeading, AmigosContainer, MutualFriends, ImageContainer } from './styles';
import { PhotosContainer, Layout } from '../MyPhotos/styles';
import { NoPosts } from '../UserPosts/styles';

import Waiting from '../Waiting';
import Image from '../Image';

import { getUserFriends, removeUserFriend } from '../../redux/actions';
import {withTranslation} from 'react-i18next';

class MyAmigos extends Component {

  componentDidMount() {
    this.props.fetchFriendList(this.props.userId);
  }

  componentDidUpdate(prevProps) {
    if(prevProps.userId !== this.props.userId) {
      this.props.fetchFriendList(this.props.userId);
    }
  }

  render() {
    const { friendList, currentUserId, userId, otherDetails,t } = this.props;
    if(_.isNull(friendList)) {
      return <Waiting />
    }

    return (
      <Layout>
        {
          _.isEmpty(friendList[0]) ? <NoPosts>{otherDetails.fname} {t("does not have any friends")}</NoPosts> :
            <PhotosContainer>
              <StyledHeading>{t("My Friends")}</StyledHeading>
              <AmigosContainer>
                { 
                  friendList.map((amigo, index) => (
                    <AmigoSection key={amigo.fullname+index}>
                      <ImageContainer>
                        <Image
                          image={amigo.profile_image}
                          alt={amigo.fullname}
                        />
                      </ImageContainer>
                      <AmigoInformation>
                        <AmigoName>{t(amigo.fullname)}</AmigoName>
                        <MutualFriends>{amigo.mutual_friend || 0} {t("mutual")} {amigo.mutual_friend > 1 ? t('friends') : t('friend')}</MutualFriends>
                        {userId === currentUserId && <StyledLink onClick={() => this.props.removeUserFriend(amigo.friend_id)}>{t("Remove")}</StyledLink>}
                      </AmigoInformation>
                    </AmigoSection>
                  ))
                }
              </AmigosContainer>
            </PhotosContainer>
        }
      </Layout>
    )
  }
}

MyAmigos.propTypes = {
  fetchFriendList: PropTypes.func.isRequired,
  friendList: PropTypes.array,
  userId: PropTypes.number.isRequired,
  removeUserFriend: PropTypes.func.isRequired,
  currentUserId: PropTypes.number,
  otherDetails: PropTypes.object,
  t: PropTypes.func
};

const mapStateToProps = (state) => ({
  friendList: state.social.friendList
});

const mapDispatchToProps = (dispatch) => ({
  fetchFriendList: (id) => dispatch(getUserFriends(id)),
  removeUserFriend: (friendId) => dispatch(removeUserFriend(friendId))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(MyAmigos));